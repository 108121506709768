<template>
  <div>
    <!-- filter -->
    <b-card
      no-body
      class="mb-1"
    >
      <b-card-header class="py-1">
        <h4 class="card-title font-weight-bolder">
          {{ $t('filters') }}
        </h4>
        <div class="d-flex align-items-center justify-content-end">
          <!-- ANCHOR Button Search -->
          <b-button
            variant="info"
            class="mr-1"
            @click="fetch()"
          >
            <span class="text-nowrap">
              <feather-icon
                class="cursor-pointer"
                icon="SearchIcon"
                size="16"
              />
              {{ $t('search') }}
            </span>
          </b-button>

          <!-- ANCHOR Button Clear Search Filters -->
          <b-button
            variant="danger"
            @click="fetch(true)"
          >
            <span class="text-nowrap">
              <feather-icon
                class="cursor-pointer"
                icon="XOctagonIcon"
                size="16"
              />
              {{ $t('clear') }}
            </span>
          </b-button>
        </div>
      </b-card-header>

      <b-card-body class="py-0">
        <b-container fluid>
          <b-row>
            <b-col md="4">
              <b-form-group
                label-class="h5 py-0"
                :label="$t('sepay.columns.fullName')"
              >
                <b-form-input
                  v-model.trim="filter.q"
                  :placeholder="$t('sepay.search.company')"
                  debounce="500"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label-class="h5 py-0"
                :label="$t('sepay.columns.status')"
              >
                <v-select
                  v-model="filter.status"
                  :placeholder="$t('sepay.columns.status')"
                  :options="status"
                  :clearable="false"
                >
                  <template #option="data">
                    <span>
                      {{ $t('sepay.status.' + data.label) }}
                    </span>
                  </template>

                  <template #selected-option="data">
                    <span>
                      {{ $t('sepay.status.' + data.label) }}
                    </span>
                  </template>

                  <template #no-options>
                    {{ $t('noOptions') }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </b-card-body>
    </b-card>

    <!-- table -->
    <b-card no-body>
      <b-overlay
        :show="loading"
        rounded="sm"
        no-fade
        variant="transparent"
        :opacity="0.5"
      >
        <b-table
          ref="refDataTable"
          bordered
          no-border-collapse
          sticky-header
          responsive
          show-empty
          primary-key="id"
          style="max-height: 70vh"
          class="position-relative"
          table-class="table-resell-ticket"
          :items="items"
          :fields="tableColumns"
          :empty-text="$t('noRecordFund')"
          :sort-by.sync="filter.sortBy"
          :sort-desc.sync="filter.isSortDirDesc"
        >
          <!-- ANCHOR Header -->
          <template
            v-for="column in tableColumns"
            #[`head(${column.key})`]="data"
          >
            <div
              :key="column.label"
              class="text-dark text-nowrap text-center"
            >
              <template>
                {{ $t(`sepay.columns.${data.label}`) }}
              </template>
            </div>
          </template>

          <!-- ANCHOR Value -->
          <template
            v-for="column in tableColumns"
            #[`cell(${column.key})`]="{ item }"
          >
            <div
              :key="column.key"
              class="text-nowrap"
              :class="{
                'text-right': typeof item[column.key] === 'number',
              }"
            >
              {{ item[column.key] }}
            </div>
          </template>

          <!-- ANCHOR stt. -->
          <template #cell(stt)="data">
            <span class="text-right">
              {{ data.index + 1 }}</span>
          </template>

          <!-- ANCHOR createdAt. -->
          <template #cell(createdAt)="{ item }">
            <span class="text-right">
              {{ convertISODateTime(item.createdAt, 0).dateTime }}
            </span>
          </template>

          <!-- ANCHOR lastTransaction. -->
          <template #cell(status)="{ item }">
            <span class="text-right">
              {{ $t(`sepay.status.${item.status}`) }}
            </span>
          </template>

          <!-- ANCHOR agency. -->
          <template #cell(agency)="{ item }">
            <span
              v-if="item.agency"
              class="text-right"
            >
              {{ item.agency?.agencyName }} ({{ item.agency?.agencyCode }})
            </span>
          </template>

          <!-- ANCHOR actions. -->
          <template #cell(actions)="{ item }">
            <div class="d-flex gap-1">
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                size="md"
                variant="outline-info"
                class="center width-100"
                @click="detailCompany(item)"
              >
                {{ $t('sepay.btn.detailCompany') }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                size="md"
                variant="outline-warning"
                class="center width-100"
                @click="detailConfig(item)"
              >
                {{ $t('sepay.btn.detailConfig') }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                size="md"
                variant="outline-success"
                class="center width-100"
                @click="detailCouter(item)"
              >
                {{ $t('sepay.btn.detailCouter') }}
              </b-button>
            </div>
          </template>
        </b-table>
      </b-overlay>
      <!-- paging -->
      <IAmPaging
        :page="filter.page"
        :size="filter.perPage"
        :total="total"
        :refDataTable="refDataTable"
        @set-page="(val)=>filter.page = val"
        @set-size="(val)=>filter.perPage = val"
      />
    </b-card>

    <!-- embed -->
    <DetailCompanyModal
      :loading="loadingForm"
      :item="companyUpdate"
      :status="status"
      @save="updateCompany(companyUpdate.id)"
    />
    <DetailConfigCompanyModal
      :loading="loadingForm"
      :item="companyUpdateConfig"
      @save="updateConfigCompany(company.id)"
    />
    <DetailCounterCompanyModal
      :loading="loadingForm"
      :item="companyCounter"
      :params="filterCompanyCounter"
      @fetch="fetchCounterCompany(company.id)"
    />
  </div>
</template>

<script>
import { computed, onMounted, ref } from '@vue/composition-api'
import {
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BContainer,
  BFormGroup,
  BFormInput,
  BOverlay,
  BPagination,
  BRow,
  BTable,
} from 'bootstrap-vue'
import { cloneDeep } from 'lodash'
import vSelect from 'vue-select'

import {
  sizePerPageLgOptions,
} from '@/constants/selectOptions'
import IAmPaging from '@/components/IAmPaging.vue'

import {
  convertISODateTime,
  formatVnCurrency,
} from '@core/utils/filter'

import useSepayHandle from '@sepay/useSepayHandle'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BCard,
    BCardHeader,
    BCardBody,
    BTable,
    BContainer,
    BRow,
    BCol,
    BOverlay,
    BButton,
    vSelect,
    IAmPaging,
    DetailCompanyModal: () => import('@sepay/modal/DetailCompanyModal.vue'),
    DetailConfigCompanyModal: () => import('@sepay/modal/DetailConfigCompanyModal.vue'),
    DetailCounterCompanyModal: () => import('@sepay/modal/DetailCounterCompanyModal.vue'),
  },
  setup() {
    //
    const {
      loading,
      loadingForm,
      companies: items,
      filterCompany: filter,
      totalCompany: total,
      fetchCompanies: fetch,
      company,
      companyCounter,
      filterCompanyCounter,
      companyConfig,
      companyUpdate,
      companyUpdateConfig,
      fetchCounterCompany,
      fetchConfigCompany,
      updateCompany,
      updateConfigCompany,
    } = useSepayHandle()

    onMounted(() => {
      fetch()
    })
    const status = ref(['Pending', 'Active', 'Suspended', 'Terminated', 'Cancelled', 'Fraud'])

    const refDataTable = ref(null)
    const tableColumns = ref([
      { key: 'stt', label: 'stt' },
      { label: 'agency', key: 'agency' },
      { label: 'fullName', key: 'fullName' },
      { label: 'shortName', key: 'shortName' },
      { label: 'status', key: 'status' },
      { label: 'createdAt', key: 'createdAt' },
      { label: 'actions', key: 'actions' },
    ])

    async function detailCompany(item) {
      this.$bvModal.show('detail-company-modal')
      company.value = cloneDeep(item)
      companyUpdate.value = cloneDeep(item)
      loadingForm.value = false
    }

    async function detailConfig(item) {
      this.$bvModal.show('detail-config-company-modal')
      await fetchConfigCompany(item.id)
      company.value = cloneDeep(item)
      companyUpdateConfig.value = cloneDeep(companyConfig.value)
      loadingForm.value = false
    }

    async function detailCouter(item) {
      this.$bvModal.show('detail-counter-company-modal')
      company.value = cloneDeep(item)
      await fetchCounterCompany(item.id)
      loadingForm.value = false
    }

    return {
      status,
      refDataTable,
      tableColumns,
      detailCompany,
      detailConfig,
      detailCouter,

      loading,
      loadingForm,
      filter,
      items,
      total,
      company,
      companyUpdate,
      companyCounter,
      companyConfig,
      fetch,
      companyUpdateConfig,
      filterCompanyCounter,
      fetchCounterCompany,
      updateCompany,
      updateConfigCompany,
      // import
      sizePerPageLgOptions,
      convertISODateTime,
      formatVnCurrency,
    }
  },
}
</script>
